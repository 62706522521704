import React, { ReactNode } from "react";
import { Container, Divider, Header } from "semantic-ui-react";

export const MainContents = ({
  pageTitle,
  children,
}: {
  pageTitle: ReactNode;
  children: ReactNode;
}) => {
  return (
    <Container text>
      <Header as="h1" textAlign="center" style={{ color: "#47395e" }}>
        {pageTitle}
      </Header>
      <Divider />
      <Container text style={{ paddingTop: "1em", paddingBottom: "1.5em" }}>
        {children}
      </Container>
    </Container>
  );
};
