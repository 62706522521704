import React from "react";
import { Helmet } from "react-helmet";
import { SiteData } from "../../consts/SiteData";
import { Image, Button, Header, Container } from "semantic-ui-react";
import { MainContents } from "../../components/MainContents";
import PageLayout from "../../components/PageLayout";
import { URIs } from "../../consts/URIs";

const NFTLoginPage = () => {
  function createMarkup() {
    return {
      __html:
        '<iframe frameborder="0" height="365" scrolling="no" src="https://camp-fire.jp/projects/503671/widget" width="245"></iframe>',
    };
  }
  return (
    <PageLayout>
      <Helmet>
        <html lang="ja" prefix="og: https://ogp.me/ns#" />
        <title>{SiteData.PAGES.NFT_LOGIN.TITLE}</title>
        <meta
          property="og:url"
          content="https://www.clsl.net/products/nft_login"
        />
        <meta property="og:type" content="og:product" />
        <meta property="og:title" content="NFT Login" />
        <meta
          property="og:description"
          content="NFT Login allows owners of specific NFTs to access private contents."
        />
        <meta property="og:site_name" content="Clarity Solutions" />
        <meta
          name="description"
          content="NFT Login allows owners of specific NFTs to access private contents."
        />
      </Helmet>
      <MainContents pageTitle="OpenID Connect IDプロバイダー「NFT Login」">
        <p>
          「NFT Login」はユーザーが NFT
          の所有者であるかどうかを検証することによってログインを許可するための
          OIDC IdP (OpenID Connect identity provider)です。特定の NFT
          の所有者に対してのみWebサイトのコンテンツを公開するような実装をしたい時に利用することができます。
          譲渡・売買が可能な NFT を使ったログインは、NFT
          を譲渡可能な会員権のように扱うことを可能とします。
          <a href={URIs.NFT_LOGIN_GITHUB} target="_blank" rel="noreferrer">
            「NFT Login」はオープンソースのプロジェクトです。
          </a>
        </p>
        <b>
          （現在はαテスト版として稼働中のため、Goerli と Mumbai
          のネットワーク上の NFT を使ったログインを「NFT
          Login」で無料でお試しいただけます）
        </b>

        <Container style={{ padding: "5em 0", "text-align": "center" }}>
          <Button
            basic
            as="a"
            href={URIs.NFT_LOGIN}
            color="blue"
            target="_blank"
            rel="noreferrer"
          >
            <Header as="b" color="blue" textAlign="center">
              NFT Login クライアントアプリ登録ページ
            </Header>
          </Button>
        </Container>
      </MainContents>
    </PageLayout>
  );
};

export default NFTLoginPage;
