const SITE_NAME = "Clarity Solutions";
const title = (mainTitle: string) => `${mainTitle} | ${SITE_NAME}`;

export const URIs = {
  DDDOG_APP: "https://apps.apple.com/jp/app/dddog/id1594115257",
  CUBIFOX: "https://cubifox.clsl.net",
  HAMSTER_RUN:
    "https://hamster-run.vercel.app/app/sensors/demo-sensor-04/daily/latest",
  HAMSTER_RUN_CUMPFIRE: "https://camp-fire.jp/projects/view/503671",
  NFT_LOGIN: "https://nft-login.clsl.net",
  NFT_LOGIN_GITHUB: "https://github.com/clarity-solutions/nft-login",
};
